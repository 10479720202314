/* eslint-disable no-extend-native */
import 'core-js';
import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import './main.scss';
import '../node_modules/bokamera-embedded-ui/dist/bokamera-ui.css';
import './main.css';

// @ts-ignore
import { iframeResizerContentWindow } from 'iframe-resizer';
import { format } from 'date-fns';

// Because server uses local ISO dates
Date.prototype.toISOString = function toLocalISOString() {
    return format(this, "yyyy-MM-dd'T'HH:mm:ss");
};

// This needs to be referenced so it isn't tree-shaken.
let a = iframeResizerContentWindow;

// render the main component
const render = (Component: ComponentType) =>
    ReactDOM.render(<Component />, document.getElementById('bokamera-embedded-profile'));

render(App);

if (module.hot) {
    module.hot.accept('./components/App', () => {
        const NextApp = require('./components/App').default;
        render(NextApp);
    });
}
