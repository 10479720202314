import React, { useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { AxiosResponse } from "axios";
import { useI18n } from "@/providers/I18nProvider";
import api from "@/utils/api";
import request from "@/utils/request";
import BEMHelper from 'react-bem-helper';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { getErrorMessage } from "@/utils/common";

import './ConfirmModal.css';

const c = new BEMHelper('ConfirmModal');

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  username: string;
}

const ConfirmModal: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  onConfirm,
  username,
}) => {
  const [step1Confimed, setStep1Confirmed] = useState(false);
  const [step2Confimed, setStep2Confirmed] = useState(false);
  const [data, setData] = useState<AxiosResponse>();
  const [error, setError] = useState<any>();
  const i18n = useI18n();

  useEffect(() => {
    if (step1Confimed) {
      request(api.deleteUser({ UserName: username }))
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => setError(err));
    }
  }, [step1Confimed]);

  useEffect(() => {
    if (step2Confimed) {
      onToggle();
      onConfirm();
    }
  }, [step2Confimed]);

  const onToggle = () => {
    setStep1Confirmed(false);
    setStep2Confirmed(false);
    setData(undefined);
    setError(undefined);
    onClose();
  };

  return (
    <Modal {...c()} isOpen={isOpen} toggle={onToggle}>
      <ModalHeader>{i18n._(t`deleteAccount.confirmDeleteTitle`)}</ModalHeader>
      <ModalBody>
        {children}
        {data ? (
          <Alert color="success">
            {i18n._(t`deleteAccount.userDeleteEmailSent`)}
          </Alert>
        ) : null}

        {!error && !data? <p>{i18n._(t`deleteAccount.confirmDeleteDescription`)}</p> : null}

        {error ? <Alert color="danger">{getErrorMessage(error)}</Alert> : null}
      </ModalBody>
      <ModalFooter>
        {!step1Confimed ? (
          <React.Fragment>
            <Button onClick={onClose}><Trans id="cancel" /></Button>
            <Button {...c("button warning")} onClick={() => setStep1Confirmed(true)}>
              <Trans id="confirm" />
            </Button>
          </React.Fragment>
        ) : null}
        
        {data ? (
            <Button onClick={() => setStep2Confirmed(true)}><Trans id="confirmModal.ok" /></Button>
        ) : null}

        {error ? <Button onClick={onToggle}><Trans id="confirmModal.ok" /></Button> : null}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
