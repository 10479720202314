import React, { useContext } from 'react';
import { I18nProvider as LinguiProvider, I18n as LinguiContextConsumer } from '@lingui/react';
import { I18n } from '@lingui/core';
import sv from '@/locales/sv/messages.js';
import en from '@/locales/en/messages.js';
import no from '@/locales/no/messages.js';
import fi from '@/locales/fi/messages.js';
import { readConfigurationProperty } from '@/utils/common';

interface I18nProviderProps {
    children: React.ReactNode;
    defaultLanguage?: string;
}

const catalogs = {
    sv,
    en,
    no,
    fi,
};

export interface I18nContextProps extends I18n {}
// @ts-ignore
export const I18nContext = React.createContext<I18nContextProps>();

export const useI18n = () => useContext(I18nContext);

const defaultLanguage = readConfigurationProperty('language') as string;

const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => {
    return (
        <LinguiProvider language={defaultLanguage || 'sv'} catalogs={catalogs}>
            <LinguiContextConsumer>
                {({ i18n }: { i18n: I18n }) => (
                    <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>
                )}
            </LinguiContextConsumer>
        </LinguiProvider>
    );
};

export default I18nProvider;
