import React from "react";
import { Spinner, Alert } from "reactstrap";
import { TypedUseQueryHookResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";

interface Props {
  queries: TypedUseQueryHookResult<any, any, any>[];
}

const RTKLoader: React.FC<Props> = ({ queries, children }) => {
  queries.some((q) => q.isError);
  return (
    <>
      {queries.every((q) => q.isSuccess) ? (
        children
      ) : queries.some((q) => q.isLoading) ? (
        <Spinner size="md" color="primary" />
      ) : queries.some((q) => q.isError) ? (
        <>
          {queries
            .filter((q) => q.isError)
            .map((q) => (
              <Alert color="danger">
                <h2>{q.endpointName}</h2>
                <p>{q.error?.error || q.error?.status}</p>
                <p>{q.error?.data?.ResponseStatus?.Message ? q.error?.data?.ResponseStatus?.Message : null}</p>
              </Alert>
            ))}
        </>
      ) : null}
    </>
  );
};

export default RTKLoader;
