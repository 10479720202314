import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { Formik, Field } from 'formik';
import { Button } from 'bokamera-embedded-ui';
import { Trans } from '@lingui/react';
import BEMHelper from 'react-bem-helper';
import FieldError from './FieldError';
import request from '../utils/request';
import api from '../utils/api';
import { useI18n } from '@/providers/I18nProvider';
import { t } from '@lingui/macro';
import { Booking } from '@/types';
import { isSameDay, differenceInDays, isAfter, toDate as fnsToDate } from 'date-fns';
import { format, parseISO } from '@/utils/localizedDateFns';
import { BookingStatus } from './BookingCard';
import { readConfigurationProperty } from '@/utils/common';

const c = new BEMHelper('CancelBooking');
const bookingCardBEM = new BEMHelper('BookingCard');

interface Props extends RouteComponentProps {
    bookingId?: string;
    cancellationCode?: string;
    returnPage: string; 
}

const FastCancelBooking = ({
    history,
    returnPage,
    bookingId: _bookingId = '',
    cancellationCode: _cancellationCode = '',
}: Props) => {
    const [bookingId, setBookingId] = useState(_bookingId);
    const [cancellationCode, setCancellationCode] = useState(_cancellationCode);
    const [booking, setBooking] = useState<Booking>();
    const [message, setMessage] = useState('');
    const i18n = useI18n();

    useEffect(() => {
        if (bookingId && cancellationCode) {
            request(api.fetchBookings({ Id: bookingId, CancellationCode: cancellationCode }))
            .then(
                (response) => {
                    setBooking(response?.data?.Results[0]);
                }
            )
            .catch(err => {
                console.log(err);
            });
        }
    }, [bookingId, cancellationCode, message]);

    const initialValues = {
        BookingNumber: bookingId,
        CancellationCode: cancellationCode,
        UnBookedComments: '',
    };

    return (
        <div>
            <Card {...c()}>
                <CardHeader {...c('header')}>
                    <h2>
                        <Trans id="cancelBooking.title" />
                    </h2>
                </CardHeader>
                <CardBody>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={async (values, { setSubmitting, setStatus, setErrors }: any) => {
                            const id = values.BookingNumber;
                            const params = {
                                CancellationCode: values.CancellationCode,
                                UnBookedComments: values.UnBookedComments,
                            };

                            request(api.deleteByCode(id, params))
                                .then((response) => {
                                    setSubmitting(false);
                                    setMessage(i18n._(t`cancelBooking.successfullyCancelled`));
                                })
                                .catch((error: any) => {
                                    setSubmitting(false);
                                    if (error?.response?.data?.ResponseStatus) {
                                        setStatus(error.response.data.ResponseStatus.Message);
                                        setErrors(
                                            error.response.data.ResponseStatus.Errors.reduce(
                                                (acc: any, error: any) => ({
                                                    ...acc,
                                                    [error.FieldName]: error.Message,
                                                }),
                                                {}
                                            )
                                        );
                                    } else if (error.message) {
                                        setStatus(error.message);
                                    }
                                });
                        }}
                    >
                        {(props) => {
                            const { isSubmitting, handleSubmit, status, values } = props;
                            setBookingId(values.BookingNumber);
                            setCancellationCode(values.CancellationCode);

                            return (
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label for="BookingNumber">
                                            <Trans id="cancelBooking.bookingNumber"></Trans>
                                        </Label>
                                        <Input type="text" name="BookingNumber" tag={Field} />
                                        <FieldError name="BookingNumber" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="CancellationCode">
                                            <Trans id="cancelBooking.cancellationCode"></Trans>
                                        </Label>
                                        <Input type="text" name="CancellationCode" tag={Field} />
                                        <FieldError name="CancellationCode" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="UnBookedComments">
                                            <Trans id="cancelBooking.UnBookedComments"></Trans>
                                        </Label>

                                        <Field
                                            {...c('textarea')}
                                            name="UnBookedComments"
                                            id="UnBookedComments"
                                            autoComplete="off"
                                            component="textarea"
                                            rows={4}
                                        />
                                        <FieldError name="UnBookedComments" />
                                    </FormGroup>

                                    {status && <Alert color="danger">{status}</Alert>}
                                    {message && <Alert color="success">{message}</Alert>}
                                    <Button
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            if(window.parentIFrame) {
                                                const targetOrigin = readConfigurationProperty('targetOrigin');
                                                window.parent.postMessage({ redirectTo: returnPage }, targetOrigin);
                                            }
                                        }}
                                        className="float-left"
                                    >
                                        <Trans id="cancelBooking.goBack"></Trans>
                                    </Button>
                                    <Button
                                        type="submit"
                                        primary
                                        disabled={isSubmitting}
                                        className="float-right"
                                    >
                                        <Trans id="cancelBooking.confirmCancel"></Trans>
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                </CardBody>
            </Card>
            {booking ? <BookingCard booking={booking} /> : null}
        </div>
    );
};

const BookingCard = ({ booking }: { booking: Booking }) => {
    const timeFormat = 'p';
    const dateFormat = 'PP';
    const fromDate = parseISO(booking.From);
    const toDate = parseISO(booking.To);
    const multiDay = !isSameDay(fromDate, toDate);
    const lastTimeToCancelDate = parseISO(booking.LastTimeToUnBook);
    const isCancellable = differenceInDays(fromDate, lastTimeToCancelDate) <= 365;

    const date = multiDay ? (
        <div {...bookingCardBEM('datetime')}>
            <div {...bookingCardBEM('time')}>{format(fromDate, timeFormat)}</div>{' '}
            <div {...bookingCardBEM('date')}>{format(fromDate, dateFormat)}</div>
            {' - '}
            <div {...bookingCardBEM('time')}>{format(toDate, timeFormat)}</div>{' '}
            <div {...bookingCardBEM('date')}>{format(toDate, dateFormat)}</div>
        </div>
    ) : (
            <div {...bookingCardBEM('datetime')}>
                <div {...bookingCardBEM('time')}>{format(fromDate, timeFormat)}</div>
                {' - '}
                <div {...bookingCardBEM('time')}>{format(toDate, timeFormat)}</div>{' '}
                <div {...bookingCardBEM('date')}>{format(toDate, dateFormat)}</div>
            </div>
        );
    return (
        <Card {...bookingCardBEM()} key={booking.Id} title={booking.Service.Name}>
            <div {...bookingCardBEM('flex')}>
                <div {...bookingCardBEM('left')}>
                    <div
                        style={{ backgroundImage: `url(${booking.Service.ImageUrl})` }}
                        {...bookingCardBEM('img')}
                    />
                </div>
                <div {...bookingCardBEM('right')}>
                    <div {...bookingCardBEM('header')}>
                        <div {...bookingCardBEM('serviceName')}>{booking.Service.Name}</div>
                    </div>
                    <div {...bookingCardBEM('subtitle')}>
                        <span {...bookingCardBEM('id')}>{booking.Id}</span>{' '}
                        <span
                            {...bookingCardBEM('status')}
                            data-testid="booking-status-info"
                            style={{ color: booking.StatusInfo.Color }}
                        >
                            <BookingStatus booking={booking} />
                        </span>
                    </div>
                    {date}
                    <dl {...bookingCardBEM('descriptionList')}>
                        <dt {...bookingCardBEM('descriptionTitle')}>
                            <Trans id="lastTimeToUnbook"></Trans>
                        </dt>
                        <dd {...bookingCardBEM('descriptionDetails')}>
                            {isCancellable ? (
                                format(lastTimeToCancelDate, 'PPp')
                            ) : (
                                    <Trans id="notCancellable"></Trans>
                                )}
                        </dd>
                    </dl>
                    {booking.CustomFieldValues.map((customFieldValue, index) => (
                        <dl {...bookingCardBEM('descriptionList')} key={index}>
                            <dt {...bookingCardBEM('descriptionTitle')}>{customFieldValue.Name}</dt>
                            <dd {...bookingCardBEM('descriptionDetails')}>
                                {customFieldValue.Value}
                            </dd>
                        </dl>
                    ))}
                </div>
            </div>
        </Card>
    );
};

export default withRouter(FastCancelBooking);
