import React, { useState } from 'react';
import { Card, CardBody, Form, Alert, Spinner} from 'reactstrap';
import { Formik } from 'formik';
import { Button } from 'bokamera-embedded-ui';
import { withI18n } from "@lingui/react";
import BEMHelper from 'react-bem-helper';

import request from '../utils/request';
import api from '../utils/api';
import { Trans } from '@lingui/react';
import './ChangePasswordForm.css';
import { realmParamsMapping } from '@/utils/common';

const c = new BEMHelper('ChangePasswordForm');

const validate = ({ Password, ConfirmPassword }: any) => {
    const errors: any = {};

    if (Password && ConfirmPassword && Password !== ConfirmPassword) {
        errors.ConfirmPassword = 'Lösenorden stämmer inte överrens';
    }
    return errors;
};

const CustomerForm = ({ initialValues, onCancel, i18n }: any) => {
    const [success, setSuccess] = useState(false);

    return (
        <Card>
            <CardBody>
                <Formik
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={(values: any, { setSubmitting, setStatus, setErrors }: any) => {
                        setStatus(undefined);
                        request(api.changePassword({
                            ...values,
                            // @ts-ignore
                            Realm: realmParamsMapping[process.env.REACT_APP_KEYCLOAK_REALM],
                            Email: initialValues.Email
                        }))
                            .then((response) => {
                                setSuccess(true);
                            })
                            .catch((error: any) => {
                                if (error?.response?.data?.ResponseStatus) {
                                    setStatus(error.response.data.ResponseStatus.Message);
                                    setErrors(
                                        error.response.data.ResponseStatus.Errors.reduce(
                                            (acc: any, error: any) => ({
                                                ...acc,
                                                [error.FieldName]: error.Message,
                                            }),
                                            {}
                                        )
                                    );
                                } else if (error.message) {
                                    setStatus(error.message);
                                }
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {(props) => {
                        const { isSubmitting, handleSubmit, status } = props;

                        if (success) {
                            return (
                                <div>
                                    <Alert color="success">
                                        <div dangerouslySetInnerHTML={{
                                            __html: i18n._('changePassword.linkSent', { email: initialValues.Email })
                                        }} />
                                    </Alert>
                                    <Button type="button" primary outline block onClick={onCancel}>
                                        Ok
                                    </Button>
                                </div>
                            );
                        }

                        return (
                            <Form onSubmit={handleSubmit}>
                                <p>
                                    <Trans id="changePassword.description" />
                                </p>
                                {status && <Alert color="danger">{status}</Alert>}
                                <Button type="button" outline onClick={onCancel}>
                                    <Trans id="cancel"></Trans>
                                </Button>
                                <Button
                                    type="submit"
                                    primary
                                    disabled={isSubmitting}
                                    {...c('submitButton')}
                                >
                                    <Trans id="changePassword.resetPassword"></Trans>
                                    {isSubmitting ? (
                                        <Spinner
                                            style={{
                                                width: '1.25rem',
                                                height: '1.25rem',
                                                marginLeft: '5px'
                                            }}
                                        />
                                    ) : null}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default withI18n()(CustomerForm);
