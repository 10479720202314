import React from "react";
import { Button, ButtonProps, Spinner } from "reactstrap";

interface Props extends ButtonProps {
  loading?: boolean;
  children: React.ReactElement;
};

const LoadingButton: React.FC<Props> = ({
  children,
  loading = false,
  ...rest
}) => {
  return !loading ? (
    <Button {...rest}>{children}</Button>
  ) : (
    <Spinner size={"md"} color="primary" />
  );
};

export default LoadingButton;
