import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
  Card,
} from "reactstrap";
import { Formik, Field } from "formik";
import { RouteComponentProps, useLocation, useParams } from "react-router";
import BEMHelper from "react-bem-helper";
import { Button } from "bokamera-embedded-ui";

import api from "../utils/api";
import request from "../utils/request";
import { Trans } from "@lingui/react";
import qs from "qs";
import "./ResetPassword.css";
import { RECAPTCHA_SITE_KEY } from "@/constants";

const c = new BEMHelper("ResetPassword");

const STATUS_SUCCESS = "success";
const STATUS_DANGER = "danger";

enum Stage {
  NotLoaded = "notLoaded",
  Loaded = 'loaded',
  NotVerified = "notVerified",
  Verified = "verified",
}

const ResetPassword = (props: RouteComponentProps) => {
  const location = useLocation();
  const { Account, Realm } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [userStage, setUserStage] = useState<Stage>(Stage.NotLoaded);

  useEffect(() => {
    //   @ts-ignore
    window.onRecaptchaLoad = () => {
      setUserStage(Stage.Loaded);
    };

    //   @ts-ignore
    window.onRecaptcha = (token) => {
      if (token) {
        setUserStage(Stage.Verified);
      } else {
        setUserStage(Stage.NotVerified);
      }
    };

    //   @ts-ignore
    window.onRecaptchaExpired = () => {
      setUserStage(Stage.NotVerified);
    };
  }, []);

  useEffect(() => {
    if (userStage === Stage.Loaded) {
      //   @ts-ignore
      grecaptcha.render("recaptcha-container", {
        sitekey: RECAPTCHA_SITE_KEY,
      });
    }
  }, [userStage]);

  if (!Account || !Realm) {
    return null;
  }

  return userStage !== Stage.NotLoaded ? (
    <Formik
      initialValues={{
        Email: Account,
      }}
      onSubmit={({ Email }, { setStatus, setSubmitting }) => {
        request(
          api.resetPassword({
            Email: Email as string,
            Realm: Realm as string,
          })
        )
          .then((response) => {
            if (response.data) {
              setStatus({
                level: STATUS_SUCCESS,
                message: response.data.ResponseStatus,
              });

              setSubmitting(false);
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.ResponseStatus) {
              setStatus({
                level: STATUS_DANGER,
                message: error.response.data.ResponseStatus.Message,
              });
            } else if (error.message) {
              setStatus({ level: STATUS_DANGER, message: error.message });
            }
            setSubmitting(false);
          });
      }}
    >
      {({ submitForm, isSubmitting, status }) => {
        const isRecaptchaVerified = !(userStage === Stage.NotVerified || userStage === Stage.Loaded);

        return (
          <div {...c("center")}>
            <Card {...c()}>
              <div {...c("logo")}>
                <img src="/logo.svg" {...c("logoImage")} />
                <span {...c("logoText")}>BokaMera</span>
              </div>
              <h1 {...c("title")}>
                <Trans id="resetPassword.title"></Trans>
              </h1>
              <Label for="Email">
                <Trans id="email"></Trans>
              </Label>
              <Input type="text" name="Email" tag={Field} readOnly={status?.level === STATUS_SUCCESS } />
              {status && status.message && (
                <Alert color={status.level}>{status.message}</Alert>
              )}
              {status?.level !== STATUS_SUCCESS ? (
                <Button
                  type="submit"
                  onClick={submitForm}
                  primary
                  disabled={!isRecaptchaVerified || isSubmitting}
                  className="float-right"
                >
                  {!isSubmitting ? <Trans id="resetPassword.submit" /> : <Spinner size="sm" />}
                </Button>
              ) : null}
              {status?.level !== STATUS_SUCCESS ? (
                <div
                  {...c("recaptchaContainer")}
                  id="recaptcha-container"
                  data-callback="onRecaptcha"
                  data-expired-callback="onRecaptchaExpired"
                />
              ) : null}
            </Card>
          </div>
        );
      }}
    </Formik>
  ) : (
      <div {...c('centerPage')}>
          <Spinner color="primary" />
      </div>
  );
};

export default ResetPassword;
