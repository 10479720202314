import qs from "qs";
import React from "react";
import { Route, RouteProps } from "react-router";

interface Props extends RouteProps {
  /**
   * These are the names of the url params 
   */
  pageParams: string[];

  /**
   * These are the keys for the render component, it will be mapped to pageParams
   * i.e pageParams={['id', code]} and renderParams={['bookingId', 'cancellationCode']}
   * will provide the render component with
   * { bookingId: <value retrieved with pageParam>, cancellationCode: <value retrieved with pageParam> }
   */
  renderParams: string[];
}

const NonAuthRoute: React.FC<Props> = ({ ...props }) => {
  const hashPath = window.location.hash.split("?")[0].replace("#", "");

  const locationSearchParsed: any = qs.parse(window.location.search);

  const searchParams = {
    ...qs.parse(
      locationSearchParsed.searchParams &&
        locationSearchParsed.searchParams.split("?")[1]
    ),
    ...qs.parse(
      locationSearchParsed.searchParams &&
        locationSearchParsed.searchParams.split("?")[2],
      {
        ignoreQueryPrefix: true,
      }
    ),
  };

  return searchParams?.path === props.path || hashPath === props.path ? (
    <Route
      {...props}
      render={(renderProps) => {
        if (!props.render) return null;

        const hashParams =
          typeof window.location.hash === "string"
            ? qs.parse(window.location.hash.split("?")[1], {
                ignoreQueryPrefix: true,
              })
            : {};

        const isHashParams = props.pageParams.every(
          (key) => typeof hashParams[key] === "string"
        );

        const isSearchParams = props.pageParams.every(
          (key) => typeof searchParams[key] === "string"
        );

        const renderParamsFromHash = props.renderParams.reduce(
          (acc, paramKey, i) => {
            // @ts-ignore
            acc[paramKey] = hashParams[props.pageParams[i]]?.toString();

            return acc;
          },
          {} as { [index: string]: string }
        );

        const renderParamsFromSearch = props.renderParams.reduce(
          (acc, paramKey, i) => {
            // @ts-ignore
            acc[paramKey] = searchParams[props.pageParams[i]]?.toString();

            return acc;
          },
          {} as { [index: string]: string }
        );

        if (isHashParams) {
          // @ts-ignore
          return <props.render { ...renderProps} {...renderParamsFromHash} />;
        } else if (isSearchParams) {
          // @ts-ignore
          return <props.render { ...renderProps} {...renderParamsFromSearch} />;
        }
        return null;
      }}
    />
  ) : null;
};

export default NonAuthRoute;
