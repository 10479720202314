import React, { useState } from 'react';
import { Card, CardBody, Modal } from 'reactstrap';
import BEMHelper from 'react-bem-helper';
import { Button } from 'bokamera-embedded-ui';

import './CustomerCard.css';
import { Trans } from '@lingui/react';
import ConfirmModal from './ConfirmModal';

const c = new BEMHelper('CustomerCard');

interface Props {
    onLogout: () => void;
    customer: any;
    onEdit: () => void;
    onChangePassword: () => void;
}

const CustomerCard: React.FC<Props> = (props) => {
    const { onLogout, customer, onChangePassword, onEdit } = props;
    const [isModalOpened, setModalOpened] = useState(false);

    return (
      <Card style={{ marginBottom: 10 }}>
        <ConfirmModal
          isOpen={isModalOpened}
          onClose={() => setModalOpened(false)}
          onConfirm={() => setModalOpened(false)}
          username={customer.Email}
        />
        <CardBody {...c()}>
          <div {...c("customerName")}>
            {customer.Firstname} {customer.Lastname}
          </div>

          <dl {...c("descriptionList")}>
            <dt {...c("descriptionTitle")}>
              <Trans id="email"></Trans>:{" "}
            </dt>
            <dd {...c("descriptionDetails")}>{customer.Email}</dd>
          </dl>
          <dl {...c("descriptionList")}>
            <dt {...c("descriptionTitle")}>
              <Trans id="phone"></Trans>:{" "}
            </dt>
            <dd {...c("descriptionDetails")}>{customer.Phone}</dd>
          </dl>

          <Button {...c("button")} outline block onClick={onEdit}>
            <Trans id="edit"></Trans>
          </Button>
          <Button {...c("button")} outline block onClick={onChangePassword}>
            <Trans id="changePassword"></Trans>
          </Button>
          <Button {...c("button")} outline block onClick={onLogout}>
            <Trans id="logOut"></Trans>
          </Button>
          <Button {...c("button warning")} outline block onClick={() => setModalOpened(true)}>
            <Trans id="deleteAccount.deleteButtonText"></Trans>
          </Button>
        </CardBody>
      </Card>
    );
};

export default CustomerCard;
