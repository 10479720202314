export const BookingStatusIds = {
    BOOKED: 1,
    UNBOOKED: 2,
    RESERVED: 3,
    CANCELED: 4,
    AWAITING_PAYMENT: 5,
    AWAITING_PAYMENT_NO_TIME_LIMIT: 6,
    BOOKED_AND_PAYED: 7,
    AWAITING_PAYMENT_REQUEST: 8,
    AWAITING_MONEY_TRANSFER: 9,
};

export const RECAPTCHA_SITE_KEY = '6Le9HxMUAAAAANyre4c_A3W71ECGM7bR_CafngAn';

export const NO_AUTH_PATHS = [
    "/cancel-booking",
    "/forgot-password",
    "/delete-account",
  ];
