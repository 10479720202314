import React, { useEffect, useState } from 'react';
import { parseISO, toDate as fnsToDate } from 'date-fns';
import BEMHelper from 'react-bem-helper';
import { Trans } from '@lingui/react';

import { Booking, Rating } from '../types';
import useRequest from '../hooks/useRequest';
import BookingCard from './BookingCard';
import api from '../utils/api';
import useRating from '@/hooks/useRating';
import { useGetBookingsQuery } from '@/services/bmApi';

interface Props {
    bookings: Booking[];
}

const c = new BEMHelper('BookingCard');

export default () => {
    const [rated, setRated] = useState<Array<number>>([]);

    const { data: bookingsData, isSuccess, refetch } = useGetBookingsQuery({});
    
    const handleSubmit = (booking: Booking) => {
        setRated([...rated, booking.Id]);
        refetch();
    };
    const bookings = bookingsData?.Results || [];
    const bookingCards = bookings.map((booking) => (
        <BookingCard.Small onSubmit={handleSubmit} key={booking.Id} booking={booking} />
    ));

    // Check if there will be any child components,
    // we hide the parent if there aren't any,
    // if rating is made, it takes it into consideration
    const isComponentVisible = bookings.some(
        (booking) => isRatingVisible(booking) && !rated.includes(booking.Id)
    );

    return isSuccess && isComponentVisible ? (
        <div {...c('ReviewTeasers')}>
            <p>
                <Trans id="reviewTeasers.shareYourExperience" />
            </p>
            <div {...c('teaserList')}>{bookingCards}</div>
        </div>
    ) : null;
};

export const isRatingVisible = (booking: Booking) => {
    const fromDate = parseISO(booking.From);
    const to = fnsToDate(fromDate);
    const now = new Date();
    const isPast = to < now;

    if (booking.Status === 'Booked' && !!booking?.RatingCode && isPast) return true;

    return false;
};
