import React from "react";
import {
  Card,
  Badge,
} from "reactstrap";
import { Trans } from "@lingui/react";
import { ApplicationState } from "@/store";
import { useAppSelector } from "@/hooks/hook";
import BEMHelper from "react-bem-helper";
import apiService, { useEditSubscribeMutation } from "@/services/bmApi";
import LoadingButton from './LoadingButton';

type Props = {};

const c = new BEMHelper("BookingCard");

const NewsletterSubscriptions: React.FC<Props> = () => {
  const { isLoading: isUsersLoading } = useAppSelector((state: ApplicationState) => {
    return apiService.endpoints.getUsers.select({})(state);
  })

  const companyCustomers = useAppSelector(
    (s: ApplicationState) => s.customer.data?.companyCustomers
  );

  const [subscribe, subscribeState] = useEditSubscribeMutation();

  return companyCustomers ? (
    <>
      {companyCustomers.map((companyCustomer) => {
        return (
          <Card
            data-testid={`subscribption-${companyCustomer.CompanyId}`}
            {...c()}
          >
            <div {...c("inner")}>
              {companyCustomer.SubscribedToNewsletter ? (
                <Badge className="mb-1" color="primary">
                  <Trans id="CompanySubscriptions.subscribed" />
                </Badge>
              ) : null}
              <div className="d-flex">
                <div style={{ flexBasis: "calc(100% / 2)" }}>
                  {companyCustomer.CompanyName}
                </div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ flexGrow: 1 }}
                >
                  {companyCustomer.SubscribedToNewsletter ? (
                    <LoadingButton
                      loading={
                        subscribeState.originalArgs?.CompanyId ===
                          companyCustomer.CompanyId &&
                        (isUsersLoading || subscribeState.isLoading)
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        subscribe({
                          CompanyId: companyCustomer.CompanyId,
                          SubscribedCompanies: companyCustomers
                            .filter(
                              (customer) =>
                                customer.CompanyId === companyCustomer.CompanyId
                            )
                            .map((customer) => ({
                              ...customer,
                              Subscribed: false,
                            })),
                        });
                      }}
                    >
                      <Trans id="CompanySubscriptions.unsubscribe" />
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      loading={
                        subscribeState.originalArgs?.CompanyId ===
                          companyCustomer.CompanyId &&
                        (isUsersLoading || subscribeState.isLoading)
                      }
                      onClick={(e) => {
                        e.preventDefault();

                        subscribe({
                          CompanyId: companyCustomer.CompanyId,
                          SubscribedCompanies: companyCustomers
                            .filter(
                              (customer) =>
                                customer.CompanyId === companyCustomer.CompanyId
                            )
                            .map((customer) => ({
                              ...customer,
                              Subscribed: true,
                            })),
                        }).then(() => {});
                      }}
                    >
                      <Trans id="CompanySubscriptions.subscribe" />
                    </LoadingButton>
                  )}
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </>
  ) : null;
};

export default NewsletterSubscriptions;
