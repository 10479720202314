import { UserResponse } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";



type InitialState = {
    data?: {
        customer: UserResponse['CustomerProfile'],
        companyCustomers: UserResponse['CompanyCustomers']
    },
};

const initialState: InitialState = {
    data: undefined
};
const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomer: (state, action: PayloadAction<{ customer: UserResponse['CustomerProfile'], companyCustomers: UserResponse['CompanyCustomers']}>) => {
            
            state.data = {
                companyCustomers: action.payload.companyCustomers,
                customer: action.payload.customer
            }
        }
    }
});

export const  { setCustomer } = customerSlice.actions;
export default customerSlice;