import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { definitions } from "@/apitypes";
import { ApplicationState } from "@/store";
import keycloak from "@/keycloak";
import { readConfigurationProperty } from "@/utils/common";
import {
  BookingResponse,
  CreateUnsubscribeQuery,
  EditSubscribeQuery,
  EditSubscribeResponse,
  GetBookingsQuery,
  GetRatingsQuery,
  GetRebateCodesQuery,
  RatingResponse,
  RebateCodeResponse,
  UnsubscribeResponse,
  UserQuery,
  UserResponse,
} from "@/types";

export const apiService = createApi({
  reducerPath: "bmApi",
  tagTypes: ["editSubscribe", "bookings", "ratings", "rebateCodes"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-language": readConfigurationProperty("language") || "sv",
    },
    prepareHeaders(headers, api) {
      const bearerToken = keycloak?.token;
      const sessionId = keycloak?.sessionId;

      if (bearerToken && sessionId) {
        headers.set("Authorization", `Bearer ${bearerToken}`);
        headers.set("X-Ss-Id", `${sessionId}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      getUsers: builder.query<UserResponse, UserQuery>({
        // transformResponse: (res: Paging<Service>) => res.Results.map(s => s.Prices).flat(),
        query: (params = {}) => ({
          url: "/users",
          method: "get",
          params: {
            IncludeCompanyCustomers: true,
            ...params
          },
        }),
        providesTags: ["editSubscribe"],
      }),
      editSubscribe: builder.mutation<
        EditSubscribeResponse,
        EditSubscribeQuery
      >({
        query: (data) => ({
          url: "/newsletter/subscribe",
          method: "put",
          body: data,
        }),
        invalidatesTags: ["editSubscribe"],
      }),
      getBookings: builder.query<BookingResponse, GetBookingsQuery>({
        query: (params) => ({
          url: "/bookings",
          method: "get",
          params: {
            OrderByDesc: "From",
            IncludeCustomFieldValues: true,
            IncludeBookedResourceTypes: true,
            IncludeServiceInformation: true,
            IncludeQuantityInformation: true,
            IncludeStatusInformation: true,
            IncludeCompanyInformation: true,
            IncludeCustomerInformation: true,
            ...params,
          },
        }),
        providesTags: ["bookings"],
      }),
      getRatings: builder.query<RatingResponse, GetRatingsQuery>({
        query: (params) => ({
          url: "/rating",
          method: "get",
          params,
        }),
        providesTags: ["ratings"],
      }),
      getRebateCodes: builder.query<RebateCodeResponse, GetRebateCodesQuery>({
        query: (params) => ({
            url: "/rebatecodes",
            method: "get",
            params,
        }),
        providesTags: ['rebateCodes']
    }),
    };
  },
});

export const {
  useGetUsersQuery,
  useEditSubscribeMutation,
  useGetBookingsQuery,
  useGetRatingsQuery,
  useGetRebateCodesQuery
} = apiService;

export default apiService;
