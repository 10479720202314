import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import BEMHelper from 'react-bem-helper';

import './Pagination.css';

const c = new BEMHelper('Pagination');

const Pagination: React.FC<ReactPaginateProps> = props => (
    <ReactPaginate
        containerClassName={c().className}
        pageClassName={c('page').className}
        pageLinkClassName={c('pageLink').className}
        breakLinkClassName={c('breakLink').className}
        activeClassName={'active'}
        activeLinkClassName={'active'}
        previousClassName={c('previous').className}
        nextClassName={c('next').className}
        previousLinkClassName={c('previousLink').className}
        nextLinkClassName={c('nextLink').className}
        disabledClassName={'disabled'}
        previousLabel="«"
        nextLabel="»"
        {...props}
    />
);

export default Pagination;
