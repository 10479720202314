import { authoriseInitialState } from "@/store";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: authoriseInitialState = {
    isError: false,
    isLoaded: false,
    isLoading: false,
    error: undefined
} 

const authoriseSlice = createSlice({
    name: 'authenticate',
    initialState,
    reducers: {
        authoriseSuccess: (state) => {
            state.isError = false;
            state.isLoaded = true;

        },
        authoriseFail: (state, action) => {
            state.isError = true;
            state.error = action.payload;
        }
    }
});
export const { authoriseFail, authoriseSuccess } = authoriseSlice.actions;

export default authoriseSlice;