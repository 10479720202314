import React from 'react';
import { ErrorMessage } from 'formik';

interface Props {
    name: string;
}

const FieldError: React.FC<Props> = ({ name }) => (
    <ErrorMessage name={name}>{msg => <div className="text-danger">{msg}</div>}</ErrorMessage>
);

export default FieldError;
