import { format as _format, parseISO as _parseISO, Locale } from 'date-fns';
import { enUS, sv } from 'date-fns/locale';

//==================================================================================
// Module contains localised wrappers for date-fns functions and some custom utils
//==================================================================================

const locales = {
    enUS,
    sv,
};

const getLocale = (): Locale => locales['sv'];

export const format = (date: Date, formatStr: string): string => {
    return _format(date, formatStr, { locale: getLocale() });
};

export const parseISO = (str: string): Date => _parseISO(str);
