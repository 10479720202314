import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Spinner,
  Col,
  Alert,
  Button,
  Input,
  FormGroup,
  Label,
  Card,
} from "reactstrap";
import { Formik, Field } from "formik";
import BEMHelper from "react-bem-helper";
import { t } from "@lingui/macro";
import api from "@/utils/api";
import { getErrorMessage, realmParamsMapping } from "@/utils/common";
import request from "@/utils/request";
import { Trans } from "@lingui/react";
import { RECAPTCHA_SITE_KEY } from "@/constants";
import { MdInfoOutline } from 'react-icons/md';
import { usePopperTooltip } from 'react-popper-tooltip';


import "./ResetPassword.css";
import "react-popper-tooltip/dist/styles.css";
import { useI18n } from "@/providers/I18nProvider";

interface Props {
  UserName: string;
  Realm: string;
  Token: string;
}

const c = new BEMHelper("ResetPassword");

enum Stage {
  NotLoaded = "notLoaded",
  Loaded = "loaded",
  NotVerified = "notVerified",
  Verified = "verified",
}

const STATUS_SUCCESS = "success";
const STATUS_DANGER = "danger";

const DeleteAccount: React.FC<Props> = ({ UserName, Realm, Token }) => {
  const i18n = useI18n();
  const [deleteCustomerProfiles, setDeleteCustomerProfiles] = useState(false);
  const [userStage, setUserStage] = useState<Stage>(Stage.NotLoaded);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement: 'right-start'});

  const toggleDeleteCustomerProfiles = () =>
    setDeleteCustomerProfiles(!deleteCustomerProfiles);

  useEffect(() => {
    if (userStage === Stage.Loaded) {
      //   @ts-ignore
      grecaptcha.render("recaptcha-container", {
        sitekey: RECAPTCHA_SITE_KEY,
      });
    }
  }, [userStage]);

  useEffect(() => {
    //   @ts-ignore
    window.onRecaptchaLoad = () => {
      setUserStage(Stage.Loaded);
    };

    //   @ts-ignore
    window.onRecaptcha = (token) => {
      if (token) {
        setUserStage(Stage.Verified);
      } else {
        setUserStage(Stage.NotVerified);
      }
    };

    //   @ts-ignore
    window.onRecaptchaExpired = () => {
      setUserStage(Stage.NotVerified);
    };
  }, []);

  return (
    <Formik
      initialValues={{
        UserName,
        Realm
      }}
      onSubmit={({ UserName }, { setStatus, setSubmitting }) => {
        request(
          api.deleteUser({
            UserName,
            DeleteCustomerProfiles: deleteCustomerProfiles,
            ForceDelete: true,
            Token,
            Realm
          })
        )
          .then((response) => {
            if (response.data) {
              setStatus({
                level: STATUS_SUCCESS,
                message: i18n._(t`deleteAccount.successfullyDeleted`),
              });

              setSubmitting(false);
            }
          })
          .catch((error: any) => {
            setStatus({
              level: STATUS_DANGER,
              message: getErrorMessage(error),
            });
            setSubmitting(false);
          });
      }}
    >
      {({ submitForm, isSubmitting, status }) => {
        const isRecaptchaVerified = !(
          userStage === Stage.NotVerified || userStage === Stage.Loaded
        );

        return (
          <div {...c("center")}>
            <Card {...c()}>
              <Row {...c("logo")}>
                <Col>
                  <img src="/logo.svg" {...c("logoImage")} />
                  <span {...c("logoText")}>BokaMera</span>
                </Col>
              </Row>
              <h1 {...c("title")}>
                <Trans id="deleteAccount.title"></Trans>
              </h1>

              {status?.level !== STATUS_SUCCESS ? <React.Fragment>
                <Row>
                  <Col>
                    <p>
                      <Trans id="deleteAccount.description" />
                    </p>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <Alert color="secondary">{UserName}</Alert>
                  </Col>
                </Row>

                <Row className="mb-3 align-items-center justify-content-center">
                  <Col className="text-center" sm={{ size: 12 }}>
                    <FormGroup className="mt-1 mb-1" check inline>
                      <Label
                        check
                        for="deleteCustomerProfiles"
                        className="mr-2"
                      >
                        <Trans id="deleteAccount.deleteCustomerProfilesLabel" />
                      </Label>
                      <Input
                        className="form-check-input m-0"
                        id="deleteCustomerProfiles"
                        type="checkbox"
                        checked={deleteCustomerProfiles}
                        onChange={toggleDeleteCustomerProfiles}
                      />
                      <span ref={setTriggerRef}>
                        <MdInfoOutline className="ml-2 mr-2" size={16} />
                        {visible ? (
                          <div
                            ref={setTooltipRef}
                            {...getTooltipProps({ className: 'tooltip-container' })}
                          >
                            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                            <Trans id="deleteAccount.deleteProfile.help" />
                          </div>
                        ) : null}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment> : null}

              {status?.level !== STATUS_SUCCESS ? (
                <Button
                  type="submit"
                  onClick={submitForm}
                  disabled={!isRecaptchaVerified}
                  color="danger"
                  className="float-right"
                >
                  {!isSubmitting ? (
                    <Trans id="deleteAccount.deleteButtonText" />
                  ) : (
                    <Spinner size="sm" />
                  )}
                </Button>
              ) : null}
              {status?.level !== STATUS_SUCCESS ? (
                <div
                  {...c("recaptchaContainer")}
                  id="recaptcha-container"
                  data-callback="onRecaptcha"
                  data-expired-callback="onRecaptchaExpired"
                />
              ) : null}
              {status && status.message && (
                <Alert className="mt-3" color={status.level}>{status.message}</Alert>
              )}
            </Card>
          </div>
        );
      }}
    </Formik>
  );
};

export default DeleteAccount;
