import React, { FunctionComponent, SFC, useContext, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router";
import { Link, Router } from "react-router-dom";
import qs from "qs";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { Provider } from 'react-redux';

import history from "@/history";
import { readConfigurationProperty } from "@/utils/common";
import ResetPassword from "./ResetPassword";
import Bookings from "./Bookings";
import MainLayout from "./MainLayout";
import NotFound from "./NotFound";
import Payment from "./Payment";
import { AuthProvider } from "./AuthContext";

import PaymentSuccess from "./PaymentSuccess";
import I18nProvider from "@/providers/I18nProvider";
import FastCancelBooking from "./FastCancelBooking";
import { ToastProvider } from "react-toast-notifications";

import { appConfig } from "../config";
import CustomRoute from "./CustomRoute";
import DeleteAccount from "./DeleteAccount";
import { NO_AUTH_PATHS } from "@/constants";
import { createStoreWithInitialState } from "@/store";
import { useAppSelector } from "@/hooks/hook";
import { addConfiguration } from "@/reducers/configuration";

if (appConfig.instrumentationKey) {
  console.log("AI");
  const reactPlugin = new ReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appConfig.instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
    },
  });

  appInsights.loadAppInsights();
}

const ProtectedRoute: FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isLoggedIn } = useAppSelector(s => s.authenticate)

  return Component ? (
    <React.Fragment>
      <Route
        render={(props) => (isLoggedIn ? <Component {...props} /> : null)}
        {...rest}
      />
    </React.Fragment>
  ) : null;
};

const MainSpace: FunctionComponent<RouteComponentProps> = () => (
  <MainLayout>
    <Switch>
      <Route path="/" component={Bookings} />
    </Switch>
  </MainLayout>
);

export const store = createStoreWithInitialState();

const locationSearchParsed: any = qs.parse(window.location.hash.split('?')[1], {ignoreQueryPrefix: true });
store.dispatch(addConfiguration(locationSearchParsed));

const App: FunctionComponent<{}> = () => {
  const useDarkTheme = readConfigurationProperty("darkTheme", false);
  const primaryColor = readConfigurationProperty("primaryColor", null);

  useEffect(() => {
    // A block to uncomment in case of  dark theme development
    if (useDarkTheme) {
      const root = document.documentElement;
      root.setAttribute("data-theme", "dark");
    }
    if (primaryColor) {
      const root = document.documentElement;
      root.style.setProperty("--bokamera-primary", primaryColor);
      root.style.setProperty("--bokamera-primary-active", primaryColor);
      root.style.setProperty("--bokamera-primary", primaryColor);
    }
  }, []);

  const locationSearchParsed: any = qs.parse(window.location.search);
  const searchParams = {
    ...qs.parse(
      locationSearchParsed.searchParams &&
        locationSearchParsed.searchParams.split("?")[1]
    ),
    ...qs.parse(
      locationSearchParsed.searchParams &&
        locationSearchParsed.searchParams.split("?")[2],
      {
        ignoreQueryPrefix: true,
      }
    ),
  };

  const params = qs.parse(window.location.search.slice(1));
  const { forwardOnly } = params;
  const hashPath = window.location.hash.split("?")[0].replace("#", "");

  const isNoAuthPath =
    NO_AUTH_PATHS.some((path) => path === hashPath) ||
    NO_AUTH_PATHS.some((path) => path === searchParams?.path);

  return (
    <Provider store={store}>
      <ToastProvider>
        <I18nProvider>
          <Router history={history}>
            {isNoAuthPath && !forwardOnly ? (
              <Switch>
                <CustomRoute
                    path="/delete-account"
                    pageParams={["Account", "Realm", "Token"]}
                    renderParams={["UserName", "Realm", "Token"]}
                    // @ts-ignore
                    render={DeleteAccount}
                  />
                <Route exact path="/forgot-password" component={ResetPassword} />
                <CustomRoute
                  path="/cancel-booking"
                  pageParams={["id", "code", "returnPage"]}
                  renderParams={["bookingId", "cancellationCode", "returnPage"]}
                  // @ts-ignore
                  render={FastCancelBooking}
                />
              </Switch>
            ) : (
              <AuthProvider>
                <Route
                  render={() => {
                    return (
                      <Switch>
                        <Route
                          exact
                          path="/payment-success"
                          component={PaymentSuccess}
                        />
                        <Route
                          exact
                          path="/payment/:bookingId"
                          component={Payment}
                        />
                        <Route
                          path="/cancel-booking"
                          component={FastCancelBooking}
                        />
                        {!forwardOnly ? (
                          <ProtectedRoute path="/" component={MainSpace} />
                        ) : null}
                        {!forwardOnly ? (
                          <Route path="*" component={NotFound} />
                        ) : null}
                      </Switch>
                    );
                  }}
                />
              </AuthProvider>
            )}
          </Router>
        </I18nProvider>
      </ToastProvider>
    </Provider>
  );
};

export default App;
