import Keycloak from "keycloak-js";
import {
  authenticateSuccess,
  authenticateFail,
  authenticateLogout,
} from "@/reducers/authenticate";
import { store } from "@/components/App";
import { getRedirectUri } from "./utils/common";
import { NO_AUTH_PATHS } from "@/constants";
import qs from "qs";

export const REFRESH_TOKEN_INTERVAL = 55; // in seconds

const locationSearchParsed: any = qs.parse(window.location.search, {ignoreQueryPrefix: true });
const { forwardOnly } = locationSearchParsed;
const hashPath = window.location.hash.split("?")[0].replace("#", "");

const searchParams = {
  ...qs.parse(
    locationSearchParsed.searchParams &&
      locationSearchParsed.searchParams.split("?")[1]
  ),
  ...qs.parse(
    locationSearchParsed.searchParams &&
      locationSearchParsed.searchParams.split("?")[2],
    {
      ignoreQueryPrefix: true,
    }
  ),
};
const isNoAuthPath =
  NO_AUTH_PATHS.some((path) => path === hashPath) ||
  NO_AUTH_PATHS.some((path) => path === searchParams?.path);

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
});

if (!isNoAuthPath && !forwardOnly) {
  keycloak
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,
      redirectUri: `${window.location.origin}/#/auth${window.location.search}`,
    })
    .then((isAuthenticated) => {
      if (isAuthenticated) {
        store.dispatch(authenticateSuccess());
      } else {
        store.dispatch(authenticateLogout());
      }
    })
    .catch((err) => {
      console.error(err);
    });

  keycloak.onAuthSuccess = () => {
    const search = window.location.hash.split("?")[1];
    const params = qs.parse(search);

    const { backUri, targetOrigin } = params;

    if (backUri) {
      let redirectUri = getRedirectUri(backUri);

      if (redirectUri) {
        window.location.replace(redirectUri);
      }
    }

    window.parent.postMessage({ loginSuccess: true }, targetOrigin as string);
  };

  keycloak.onAuthLogout = () => {
    const search = window.location.hash.split("?")[1];
    const params = qs.parse(search);

    const { targetOrigin } = params;

    window.parent.postMessage({ logout: true }, targetOrigin as string);
  }

  keycloak.onAuthRefreshSuccess = () => {
    store.dispatch(authenticateSuccess());
  };

  keycloak.onAuthError = (err) => {
    store.dispatch(authenticateLogout());
  };

  keycloak.onAuthRefreshError = () => {
    const err = new Error("Token refresh error;");
    store.dispatch(authenticateFail(err));
  };

  keycloak.onTokenExpired = () => {
    keycloak.updateToken(REFRESH_TOKEN_INTERVAL);
  };
}

export default keycloak;
