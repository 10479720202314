import React, { SFC } from 'react';

interface Props {
    children: React.ReactNode;
}

const MainLayout: SFC<Props> = ({ children }) => {
    return <div>{children}</div>;
};

export default MainLayout;
