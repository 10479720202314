import React from 'react';
import './Stars.css';


interface Props {
    rating: number;
    onRate?: (arg: number) => void;
    active?: boolean;
    [x: string]: any;
}

const Stars: React.FC<Props> = ({
    rating,
    inputName,
    onRate = () => {},
    active = false,
    ...restProps
  }) => {
    const id1 = `${Date.now().toString()}-id1`;
    const id2 = `${Date.now().toString()}-id2`;
    const id3 = `${Date.now().toString()}-id3`;
    const id4 = `${Date.now().toString()}-id4`;
    const id5 = `${Date.now().toString()}-id5`;

    if (rating > 5) return null;

    return (
      <div className={`${active ? "active" : ""} stars rating`} {...restProps}>
        <input
          readOnly
          checked={rating === 5}
          type="radio"
          name={inputName}
          id={id5}
        />
        <label onClick={() => onRate(5)} htmlFor={id5}></label>
        <input
          readOnly
          checked={rating === 4}
          type="radio"
          name={inputName}
          id={id4}
        />
        <label onClick={() => onRate(4)} htmlFor={id4}></label>
        <input
          readOnly
          checked={rating === 3}
          type="radio"
          name={inputName}
          id={id3}
        />
        <label onClick={() => onRate(3)} htmlFor={id3}></label>
        <input
          readOnly
          checked={rating === 2}
          type="radio"
          name={inputName}
          id={id2}
        />
        <label onClick={() => onRate(2)} htmlFor={id2}></label>
        <input
          readOnly
          checked={rating === 1}
          type="radio"
          name={inputName}
          id={id1}
        />
        <label onClick={() => onRate(1)} htmlFor={id1}></label>
      </div>
    );
  }


export default Stars;
