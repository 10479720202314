import React from 'react';
import { Card, CardBody, Form, FormGroup, Label, Input, Alert, Spinner } from 'reactstrap';
import { Formik, Field } from 'formik';
import { Button } from 'bokamera-embedded-ui';

import request from '../utils/request';
import api from '../utils/api';
import FieldError from './FieldError';
import { Trans } from '@lingui/react';

const CustomerForm = ({ initialValues, onSubmitSuccess, onCancel }: any) => {
    return (
        <Card>
            <CardBody>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values: any, { setSubmitting, setStatus, setErrors }: any) => {
                        let apiPayload = {...values};

                        if(initialValues.Email !== values.Email) {
                            delete apiPayload.Email;
                            apiPayload['NewEmail'] = values.Email;
                        }
                        
                        request(api.editUser(apiPayload))
                            .then((response) => {
                                setSubmitting(false);
                                onSubmitSuccess(response.data.UserProfile);
                            })
                            .catch((error: any) => {
                                setSubmitting(false);
                                if (error?.response?.data?.ResponseStatus) {
                                    setStatus(error.response.data.ResponseStatus.Message);
                                    setErrors(
                                        error.response.data.ResponseStatus.Errors.reduce(
                                            (acc: any, error: any) => ({
                                                ...acc,
                                                [error.FieldName]: error.Message,
                                            }),
                                            {}
                                        )
                                    );
                                } else if (error.message) {
                                    setStatus(error.message);
                                }
                            });
                    }}
                >
                    {(props) => {
                        const { isSubmitting, handleSubmit, status } = props;
                        return (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="Firstname">
                                        <Trans id="firstname"></Trans>
                                    </Label>
                                    <Input type="text" name="Firstname" tag={Field} />
                                    <FieldError name="name" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Lastname">
                                        <Trans id="lastname"></Trans>
                                    </Label>
                                    <Input type="text" name="Lastname" tag={Field} />
                                    <FieldError name="name" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Email">
                                        <Trans id="email"></Trans>
                                    </Label>
                                    <Input style={{ color: 'var(--bokamera-grey-500)' , backgroundColor: 'var(--bokamera-grey-300)' }} type="email" name="Email" tag={Field} readOnly={true} />
                                    <FieldError name="name" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Phone">
                                        <Trans id="phone"></Trans>
                                    </Label>
                                    <Input type="text" name="Phone" tag={Field} />
                                    <FieldError name="name" />
                                </FormGroup>

                                <Button outline onClick={onCancel}>
                                    <Trans id="cancel"></Trans>
                                </Button>
                                {status && <Alert className='mt-3' color="danger">{status}</Alert>}
                                <Button
                                    type="submit"
                                    primary
                                    disabled={isSubmitting}
                                    className="float-right"
                                >
                                    
                                    {!isSubmitting ? <Trans id="save" /> : <Spinner size="sm" />}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default CustomerForm;
