import React from 'react';

// Not found page component
export default class NotFound extends React.Component {
    // render
    render() {
        return (
            <div className="page-not-found">
                <h4>Page not found</h4>
            </div>
        );
    }
}
