import React, { useState, useCallback, useReducer, useEffect } from 'react';
import { Booking, Rating } from '@/types';
import request from '../utils/request';
import useRequest from '../hooks/useRequest';
import api from '../utils/api';
import { useGetRatingsQuery } from '@/services/bmApi';

interface Props {
    booking: Booking;
}

interface BookingRatingState {
    bookingId: number;
    ratingScore: number;
    ratingCode: string;
    error?: string;
}

const initialBookingRating: BookingRatingState = {
    bookingId: 0,
    ratingScore: 0,
    ratingCode: '',
    error: '',
};

const ratingReducer = (
    state: BookingRatingState,
    action: BookingRatingState | null
): BookingRatingState => {
    if (action && action.ratingScore > 0) {
        return { ...state, ...action };
    }

    return initialBookingRating;
};

function useRating(props: Props) {
    const { booking } = props;
    const [currentRating, setCurrentRating] = useReducer(ratingReducer, initialBookingRating);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const ratingsState = useGetRatingsQuery({
        BookingId: booking.Id,
        CompanyId: booking.CompanyId,
        Active: true,
        IncludeRatingReviews: true,
    })

    const bookingRating: Rating = (ratingsState?.data?.Results || [])[0];
    return {
        isSubmitted,
        setIsSubmitted,
        currentRating,
        setCurrentRating,
        bookingRating,
        error: ratingsState.error,
    };
}

export default useRating;
