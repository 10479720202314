import bmApi from "@/services/bmApi";
import { ApplicationDispatch, ApplicationState, authenticateInitialState, LoadEntity } from "@/store";
import { createListenerMiddleware, createSlice, isAnyOf, TypedStartListening } from "@reduxjs/toolkit";
import { setCustomer } from "./customer";
import { authoriseFail } from './authorise'

export const initialState: authenticateInitialState = {
    isError: false,
    isLoaded: false,
    isLoading: false,
    isLoggedIn: false,
    error: undefined
};

export type AuthStartListening = TypedStartListening<ApplicationState, ApplicationDispatch>;

export const authListener = createListenerMiddleware();

const startAuthListening = authListener.startListening as AuthStartListening;

const authenticateSlice = createSlice({
    name: 'authenticate',
    initialState,
    reducers: {
        authenticateSuccess: (state) => {
            state.isLoading = false;
            state.isLoaded = true;
            state.isLoggedIn = true;
            state.isError = false;
        },
        authenticateFail: (state, action) => {
            state.isLoading = false;
            state.isLoaded = false;
            state.isLoggedIn = false;
            state.isError = true;
            state.error = action.payload;
        },
        authenticateLogout: (state) => {
            state.isLoading = false;
            state.isLoaded = true;
            state.isLoggedIn = false;
            state.isError = false;
            state.error = undefined;
        }
    }
});


export const { authenticateFail, authenticateSuccess, authenticateLogout } = authenticateSlice.actions;

startAuthListening({
    matcher: isAnyOf(
        authenticateSuccess,
        bmApi.endpoints.getUsers.matchFulfilled,
        bmApi.endpoints.getUsers.matchRejected
    ),
    effect: (action, api) => {
        if(authenticateSuccess.match(action)) {
            // @ts-ignore
            api.dispatch(bmApi.endpoints.getUsers.initiate({}));
        }

        if(bmApi.endpoints.getUsers.matchFulfilled(action)) {
            api.dispatch(
              setCustomer({
                customer: action.payload.CustomerProfile,
                companyCustomers: action.payload.CompanyCustomers,
              })
            );
        }

        if(bmApi.endpoints.getUsers.matchRejected(action)) {
            api.dispatch(authoriseFail(action.error));
        }
    }
})

export default authenticateSlice;
