import React from 'react';
import { MdCheck } from 'react-icons/md';
import { Container } from 'reactstrap';
import { Button } from 'bokamera-embedded-ui';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';
import { Trans } from '@lingui/react';

interface Props extends RouteComponentProps { }

export const PaymentSuccess: React.FC<Props> = ({ history }) => {
    const { bookingId, email } = qs.parse(document.location.search, {
        ignoreQueryPrefix: true,
    });

    return (
        <Container>
            <div className="text-center">
                <h1 className="display-4">
                    <Trans id="successfullyPaid"></Trans>
                </h1>
                <p>
                    <Trans id="yourBookingIsPaid" values={{ bookingId }}></Trans>
                </p>
                <p>
                    <Trans id="yourConfirmationSentTo"></Trans> {email}
                </p>
                <p className="text-success">
                    <MdCheck size={64} />
                </p>
                <Button
                    color="success"
                    onClick={() => {
                        history.push('/');
                    }}
                >
                    Ok
                </Button>
            </div>
        </Container>
    );
};

export default PaymentSuccess;
